import React from "react";

import {
    REASON_TO_WATCH_TYPE_AWARD,
    REASON_TO_WATCH_TYPE_CONCEPT_SOURCE,
    REASON_TO_WATCH_TYPE_CRITIC_REVIEW,
    REASON_TO_WATCH_TYPE_MASHUP,
    REASON_TO_WATCH_TYPE_PEOPLE,
    REASON_TO_WATCH_TYPE_KEY_FACTS
    //UI
} from "../constants";

import IconAwardSVG from "../App/IconAwardSVG";
import IconConceptSourceSVG from "../App/IconConceptSourceSVG";
import IconCriticReviews from "../App/IconCriticReviews";
import IconMashupSVG from "../App/IconMashupSVG";
import IconPeopleSVG from "../App/IconPeopleSVG";
import IconKeyFactsSVG from "../App/IconKeyFactsSVG"

const reasonTypeToIconMap = new Map([
    [REASON_TO_WATCH_TYPE_AWARD, IconAwardSVG],
    [REASON_TO_WATCH_TYPE_CONCEPT_SOURCE, IconConceptSourceSVG],
    [REASON_TO_WATCH_TYPE_CRITIC_REVIEW, IconCriticReviews],
    [REASON_TO_WATCH_TYPE_MASHUP, IconMashupSVG],
    [REASON_TO_WATCH_TYPE_PEOPLE, IconPeopleSVG],
    [REASON_TO_WATCH_TYPE_KEY_FACTS, IconKeyFactsSVG]
]);

/**
 * How the reason to watch was generated, by reason type.
 */
export const MACHINE_LEARNING = "ML";
export const EDITORIAL = "E";
const reasonTypeToGenerationMap = new Map([
    [REASON_TO_WATCH_TYPE_AWARD, [MACHINE_LEARNING]],
    [REASON_TO_WATCH_TYPE_CONCEPT_SOURCE, [MACHINE_LEARNING]],
    [REASON_TO_WATCH_TYPE_CRITIC_REVIEW, [EDITORIAL]],
    [REASON_TO_WATCH_TYPE_MASHUP, [MACHINE_LEARNING, EDITORIAL]],
    [REASON_TO_WATCH_TYPE_PEOPLE, [MACHINE_LEARNING]],
    [REASON_TO_WATCH_TYPE_KEY_FACTS, [MACHINE_LEARNING, EDITORIAL]]
]);
export const RTWTypeGenerator = ({ type, containerStyle, itemStyle }) => {
    const generatorsArray = reasonTypeToGenerationMap.get(type);
    const generatorsDisplay = generatorsArray.map((g, i, generatorsArray) => {
        let element = g;
        if (i + 1 < generatorsArray.length) {
            element = `${g},\u00A0`;
        }
        return <span style={itemStyle ? itemStyle : {}}>{element}</span>;
    });
    return (
        <span style={containerStyle ? containerStyle : { color: "white" }}>
            {generatorsDisplay}
        </span>
    );
};

export const RTWTypeGeneratorSeparable = ({
    type,
    Component,
    boxColors,
    itemStyle,
}) => {
    const TMP = Component || "span";
    const colors = boxColors || {};
    const generatorsArray = reasonTypeToGenerationMap.get(type);
    const generatorsDisplay = generatorsArray.map((g, i) => {
        return (
            <TMP
                bgColor={
                    Object.keys(colors).length && colors.hasOwnProperty(g)
                        ? colors[g]
                        : undefined
                }

                key={`${g}_${i}`}
                style={
                    itemStyle
                        ? itemStyle
                        : {
                            color: "white",
                        }
                }
            >
                {g}
            </TMP>
        );
    });
    return generatorsDisplay;
};

export const RTWTypeIcon = ({ type, style, color }) => {
    const TMP = reasonTypeToIconMap.get(type);
    return (
        <TMP
            style={style ? style : { width: "1.75rem", marginRight: ".5rem" }}
            color={color || "white"}
        />
    );
};
