import React from "react";
const IconKeyFactsSVG = (props) => {
    const color = props.color || "black";
    const { style } = props
    return (
        <div style={style}>
            <svg viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={color} d="M3.71875 3.28125V0.226953C3.71875 0.103906 3.81445 0 3.9375 0C5.62871 0 7 1.37129 7 3.0625C7 3.18555 6.89609 3.28125 6.77305 3.28125H3.71875ZM0 3.71875C0 2.06035 1.23184 0.687695 2.83008 0.468945C2.95586 0.451172 3.0625 0.552344 3.0625 0.679492V3.9375L5.20215 6.07715C5.29375 6.16875 5.28691 6.31914 5.18164 6.39297C4.6457 6.77578 3.98945 7 3.28125 7C1.46973 7 0 5.53164 0 3.71875ZM7.19688 3.9375C7.32402 3.9375 7.42383 4.04414 7.40742 4.16992C7.30215 4.93418 6.93437 5.61367 6.39707 6.11543C6.31504 6.19199 6.18652 6.18652 6.10723 6.10586L3.9375 3.9375H7.19688Z" />
            </svg>
        </div>
    );
}

export default IconKeyFactsSVG;
